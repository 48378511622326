import React from 'react'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Grid, Typography } from '@material-ui/core'

// Format based on user's localization
const formatDate = date => moment(date).format('ll')

const useStyles = makeStyles(
	theme => ({
		authorName: {
			color: theme.palette.secondary.main,
		},
		textSpace: {
			marginRight: theme.spacing(),
		},
		textSeparator: {
			fontWeight: theme.typography.fontWeightBold,
			margin: theme.spacing(0, 1),
		},
	}),
	{ name: 'AuthorBlock' }
)

const AuthorBlock = ({ author, date, timeToRead, ...rest }) => {
	const classes = useStyles()

	return (
		<Grid container alignItems={'center'} spacing={2} {...rest}>
			<Grid item>
				<Avatar alt={`${author.name} avatar`} src={author.avatarUrl} />
			</Grid>
			<Grid item xs>
				<Typography variant={'body2'}>
					<span className={classes.authorName}>{author.name}</span>
					<span className={classes.textSpace} />
					{formatDate(date)}
					<span className={classes.textSeparator}>·</span>
					{timeToRead} min read
				</Typography>
			</Grid>
		</Grid>
	)
}

export default AuthorBlock
