import React, { useMemo, useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useMatch } from '@reach/router'
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer'
import cx from 'classnames'
import orderBy from 'lodash/orderBy'
import startCase from 'lodash/startCase'
import throttle from 'lodash/throttle'

import { lighten, makeStyles } from '@material-ui/core/styles'
import { Breadcrumbs, Container, Typography } from '@material-ui/core'
import { ChevronRight as ChevronRightIcon } from 'mdi-material-ui'

import {
	FloatingVerticalNav,
	VerticalNavHeader,
	VerticalNavItem,
} from '../components/nav'
import { usePageTransitionStyles } from '../utils/transitions'
import AuthorBlock from '../components/AuthorBlock'
import Layout from '../components/Layout'
import Link from '../components/Link'
import MdxWrapper from '../components/MdxWrapper'

export const pageQuery = graphql`
	query($id: String!) {
		mdx(fields: { id: { eq: $id } }) {
			fields {
				title
				description
				date
				author {
					name
					avatarUrl
				}
				banner {
					...bannerImage960
				}
				bannerAlt
				slug
			}
			bannerRemoteImage {
				...bannerImage960
			}
			body
			tableOfContents
			timeToRead
		}
		articles: allMdx(
			filter: {
				frontmatter: { published: { ne: false } }
				fileAbsolutePath: { regex: "//content/help//" }
			}
			sort: { fields: frontmatter___date, order: DESC }
		) {
			edges {
				node {
					fields {
						id
						title
						slug
					}
				}
			}
		}
	}
`

const useStyles = makeStyles(
	theme => {
		const sidebarWidth = theme.spacing(22)
		return {
			'@global': {
				html: {
					scrollBehavior: 'smooth',
				},
			},
			articleMeta: {
				paddingBottom: theme.spacing(4),

				'& h2': {
					fontSize: '3rem',
					fontWeight: 900,
					marginBottom: theme.spacing(2),
				},

				'& h5': {
					color: theme.palette.text.secondary,
					marginBottom: theme.spacing(2),
				},
			},
			authorBlock: {
				marginTop: theme.spacing(2),
			},
			body: {
				padding: theme.spacing(4, 0),
			},
			breadcrumbs: {
				marginBottom: theme.spacing(2),
			},
			content: {
				paddingBottom: theme.spacing(3),
				paddingTop: theme.spacing(3),

				'& p, & ul': {
					color: lighten(theme.palette.text.primary, 0.15),
				},
			},
			middleColumn: {
				margin: '0 auto',
				maxWidth: theme.spacing(8 * 11),
				position: 'relative',
				width: '100%',

				[theme.breakpoints.up('md')]: {
					width: `calc(100% - ${sidebarWidth}px)`,
				},
			},
		}
	},
	{ name: 'PostPage' }
)

const PostPage = ({ data: { articles, mdx } = {}, ...rest }) => {
	const [selectedHeading, setSelectedHeading] = useState({})
	const match = useMatch('/:type/:section?/:article')
	const classes = useStyles()
	const transitionClasses = usePageTransitionStyles({ rest })
	const headingsRef = useRef({})

	const { bannerRemoteImage, fields, tableOfContents, timeToRead } = mdx
	const { author, banner, bannerAlt, date, description, slug, title } = fields
	const image = getImage(banner) || getImage(bannerRemoteImage)

	const handleScroll = useMemo(
		() =>
			throttle(() => {
				const position = window.pageYOffset
				const headings = orderBy(
					headingsRef.current,
					['offsetTop'],
					['desc']
				)

				const heading = headings.find(
					heading => position >= heading.offsetTop
				)
				setSelectedHeading(heading)
			}, 100),
		[]
	)

	useEffect(() => {
		handleScroll()
		window.addEventListener('scroll', handleScroll, { passive: true })
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [handleScroll])

	useEffect(() => {
		tableOfContents.items.forEach(item => {
			const element = document.getElementById(item.url.slice(1))
			if (element) {
				headingsRef.current[item.url] = {
					id: item.url,
					element,
					offsetTop: element.offsetTop,
				}
			}
		})
	}, [tableOfContents])

	const navItems = articles.edges.map(({ node }) => ({
		title: node.fields.title,
		url: node.fields.slug,
	}))

	const slugPieces = slug.split('/')
	const crumbs = slugPieces
		.slice(1, slugPieces.length - 1)
		.map((crumb, index) => ({
			title: startCase(crumb),
			url: slugPieces.slice(0, index + 2).join('/'),
		}))

	return (
		<Layout>
			<MdxWrapper>
				<div
					className={cx(
						classes.middleColumn,
						transitionClasses[rest.transitionStatus]
					)}
				>
					<Container maxWidth={'md'}>
						<FloatingVerticalNav>
							<VerticalNavHeader>On this page</VerticalNavHeader>
							{tableOfContents.items.map(item => {
								const isSelected =
									selectedHeading?.id === item.url
								return (
									<VerticalNavItem
										key={item.url}
										isSelected={isSelected}
										{...item}
									/>
								)
							})}

							<VerticalNavHeader>Articles</VerticalNavHeader>
							{navItems.map(item => {
								const isSelected =
									match?.article ===
									item.url.replace(
										/^.*\/([^/]+)(?:#.)?$/,
										'$1'
									)
								return (
									<VerticalNavItem
										key={item.url}
										isSelected={isSelected}
										variant={'page'}
										{...item}
									/>
								)
							})}
						</FloatingVerticalNav>

						<div className={classes.content}>
							<Breadcrumbs
								className={classes.breadcrumbs}
								aria-label={'breadcrumb'}
								separator={
									<ChevronRightIcon fontSize={'small'} />
								}
							>
								{crumbs.map(({ title, url }) => (
									<Link color={'inherit'} key={url} to={url}>
										{title}
									</Link>
								))}
								<Typography color={'textPrimary'}>
									{title}
								</Typography>
							</Breadcrumbs>
							<div className={classes.articleMeta}>
								<Typography gutterBottom variant={'h2'}>
									{title}
								</Typography>
								<Typography gutterBottom variant={'h5'}>
									{description}
								</Typography>
								<AuthorBlock
									author={author}
									date={date}
									timeToRead={timeToRead}
								/>
							</div>
							{image ? (
								<GatsbyImage image={image} alt={bannerAlt} />
							) : null}
							<div className={classes.body}>
								<MDXRenderer>{mdx.body}</MDXRenderer>
							</div>
						</div>
					</Container>
				</div>
			</MdxWrapper>
		</Layout>
	)
}

export default PostPage
