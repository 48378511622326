import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import slugify from '@sindresorhus/slugify'

import { makeStyles } from '@material-ui/core/styles'
import {
	Link as MuiLink,
	Table,
	TableCell,
	TableRow,
	Typography,
} from '@material-ui/core'

import Link from './Link'
import { isAbsoluteUrl } from '../utils/urls'

const useContentStyles = makeStyles(
	theme => {
		const contentRhythm = '1.5rem'
		const headerStyles = {
			fontWeight: theme.typography.fontWeightBold,
			margin: `calc(${contentRhythm} / 2) 0`,
			lineHeight: 1.25,
		}

		return {
			body1: {
				fontSize: '1.25rem',
				lineHeight: 1.5,
				marginBottom: contentRhythm,
			},
			code: {
				backgroundColor: '#eff1f5',
				borderRadius: theme.shape.borderRadius,
				display: 'block',
				fontSize: '1.25rem',
				padding: theme.spacing(3),
				whiteSpace: 'pre-wrap',

				'& > code': {},
			},
			h2: {
				fontSize: '1.875rem',
				...headerStyles,
			},
			h3: {
				fontSize: '1.5625rem',
				...headerStyles,
			},
			h4: {
				fontSize: '1.25rem',
				...headerStyles,
			},
			h5: {
				fontSize: '1.09375rem',
				...headerStyles,
			},
			h6: {
				color: theme.palette.text.secondary,
				fontSize: '1.0625rem',
				...headerStyles,
			},
			image: {
				maxWidth: '100%',
			},
			inlineCode: {
				backgroundColor: '#eceff3',
				borderRadius: theme.shape.borderRadius / 2,
				fontSize: '1.25rem',
				padding: '0.1em 0.25em',

				'& > code': {},
			},
			list: {
				fontSize: '1.25rem',
				lineHeight: 1.5,
				marginBottom: contentRhythm,
				marginTop: 0,
				paddingLeft: theme.spacing(3),
			},
			quote: {
				borderLeft: `2px solid ${theme.palette.primary.main}`,
				fontSize: '1.25em',
				fontStyle: 'italic',
				margin: theme.spacing(0, 0, contentRhythm),
				padding: theme.spacing(2, 3),

				'& p': {
					margin: 0,
				},
			},
			pre: { margin: `0 0 ${contentRhythm}` },
			subtitle1: { fontSize: '1.0625rem', ...headerStyles },
			table: { marginBottom: contentRhythm },
			tableCell: { fontSize: '1rem' },
		}
	},
	{ name: 'ContentStyles' }
)

const H1 = props => {
	const classes = useContentStyles()
	return (
		<Typography
			id={slugify(props.children)}
			className={classes.h2}
			variant={'h2'}
			{...props}
		/>
	)
}
const H2 = props => {
	const classes = useContentStyles()
	return (
		<Typography
			id={slugify(props.children)}
			className={classes.h3}
			variant={'h3'}
			{...props}
		/>
	)
}
const H3 = props => {
	const classes = useContentStyles()
	return (
		<Typography
			id={slugify(props.children)}
			className={classes.h4}
			variant={'h4'}
			{...props}
		/>
	)
}
const H4 = props => {
	const classes = useContentStyles()
	return (
		<Typography
			id={slugify(props.children)}
			className={classes.h5}
			variant={'h5'}
			{...props}
		/>
	)
}
const H5 = props => {
	const classes = useContentStyles()
	return (
		<Typography
			id={slugify(props.children)}
			className={classes.h6}
			variant={'h6'}
			{...props}
		/>
	)
}
const H6 = props => {
	const classes = useContentStyles()
	return (
		<Typography
			id={slugify(props.children)}
			className={classes.subtitle1}
			variant={'subtitle1'}
			{...props}
		/>
	)
}
const Paragraph = props => {
	const classes = useContentStyles()
	return <Typography className={classes.body1} variant={'body1'} {...props} />
}

const Ol = props => {
	const classes = useContentStyles()
	return <ol className={classes.list} {...props} />
}
const Ul = props => {
	const classes = useContentStyles()
	return <ul className={classes.list} {...props} />
}

const Pre = props => {
	const classes = useContentStyles()
	return <pre className={classes.pre} {...props} />
}

const Code = props => {
	const classes = useContentStyles()
	return <code className={classes.code} {...props} />
}

const InlineCode = props => {
	const classes = useContentStyles()
	return <code className={classes.inlineCode} {...props} />
}

const TableWrapper = props => {
	const classes = useContentStyles()
	return <Table className={classes.table} {...props} />
}

const TableCellHead = props => {
	const classes = useContentStyles()
	return (
		<TableCell variant={'head'}>
			<div className={classes.tableCell} {...props}></div>
		</TableCell>
	)
}
const TableCellBody = props => {
	const classes = useContentStyles()
	return (
		<TableCell>
			<div className={classes.tableCell} {...props}></div>
		</TableCell>
	)
}

const Image = props => {
	const classes = useContentStyles()
	return <img className={classes.image} alt={props.alt} {...props} />
}
const Anchor = ({ href, ...rest }) => {
	if (isAbsoluteUrl(href)) {
		return <MuiLink href={href} {...rest} />
	} else {
		return <Link to={href} {...rest} />
	}
}

const Quote = props => {
	const classes = useContentStyles()
	return <blockquote className={classes.quote} {...props} />
}

const components = {
	h1: H1,
	h2: H2,
	h3: H3,
	h4: H4,
	h5: H5,
	h6: H6,
	p: Paragraph,
	a: Anchor,
	blockquote: Quote,
	ol: Ol,
	ul: Ul,
	code: Code,
	pre: Pre,
	inlineCode: InlineCode,
	table: TableWrapper,
	tr: TableRow,
	td: TableCellBody,
	th: TableCellHead,
	img: Image,
}

const MdxWrapper = ({ children }) => {
	return <MDXProvider components={components}>{children}</MDXProvider>
}

export default MdxWrapper
